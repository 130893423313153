<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->
	
		<div class="card mt-3 shadow">
			<div class="card-header white">
				
				<div class="float-right mt-2">
					<button v-if="customergroup.id>0" @click="back" type="button" class="btn btn-outline-secondary"> <i class="icon icon-keyboard_arrow_left mr-0 pr-0"></i>返回 </button>
				</div>
				<div class="float-left">
					<h3>当前级别：{{customergroup.name}}</h3>
					{{outlet.name}}
				</div>
			</div>
			<div class="card-body">
				<div class="" novalidate>
					
					
					<h4 class="text-blue">消费额度限制 <i class="icon icon-keyboard_arrow_down"></i></h4>
					<h6 class="mb-3">不设置表示不限制，空或0表示不设置</h6>
					
					<div class="form-row">
						<div class="col-md-3 mb-3">
							<label for="">单次限额(元)</label>
							<input v-model.number="paylimit.max_pay" type="number" class="form-control"  placeholder="">
							<small class="form-text text-muted">
								<!-- 单次刷卡最大可消费金额 -->
							</small>
						</div>
						
					</div>
					
					<!-- <div class="card my-3 no-b m-2 p-1"> -->
					<!-- <div class="row card-body grey lighten-4 p-0"> -->
					<div class="row">
						<!-- :class="[ 'col ',{'grey lighten-3' : index%2 == 0}]" -->
						<div class="col" v-for="(item, index) in businesshourssetting" v-bind:key="index">
						<h6 class="mb-2">时段{{index+1}}:{{item.name}} {{item.start_date}}~{{item.end_date}}</h6>
						
						<div class="form-row">
							<div class="form-group col">
								<label for="">限额(元)</label>
								<input v-model.number="paylimit.hours[index].pay" type="number" class="form-control" placeholder="">
								<small class="form-text text-muted">
								</small>
							</div>
							
							<div class="form-group col">
								<label for="">限次</label>
								<input v-model.number="paylimit.hours[index].times" type="number" class="form-control" placeholder="">
								<small class="form-text text-muted">
								</small>
							</div>
						</div>
						
						</div>
					</div>
					<!-- </div> -->
					
					<div class="form-row">
						<div class="col-md-3 mb-3">
							<label for="">每日限额(元)</label>
							<input v-model.number="paylimit.day.pay" type="number" class="form-control" placeholder="">
							<small class="form-text text-muted">
								<!-- 每天累计可消费金额 -->
							</small>
						</div>
						<div class="col-md-3 mb-3">
							<label for="">每日限次</label>
							<input v-model.number="paylimit.day.times" type="number" class="form-control" placeholder="">
							<small class="form-text text-muted">
								<!-- 每天累计可刷卡次数 -->
							</small>
							
						</div>
						<div class="col-md-3 mb-3">
							<label for="">每月限额(元)</label>
							<input v-model.number="paylimit.month.pay" type="number" class="form-control" placeholder="">
							<small class="form-text text-muted">
								<!-- 本月累计可消费金额 -->
							</small>
						</div>
						<div class="col-md-3 mb-3">
							<label for="">每月限次</label>
							<input v-model.number="paylimit.month.times" type="number" class="form-control" placeholder="">
							<small class="form-text text-muted">
								<!-- 本月累计可刷卡次数 -->
							</small>
						</div>
					</div>
					
					<button @click="save" :disabled="disabled" type="button" class="btn btn-primary"> 保存 </button>
				</div>
	
	
			</div>
		</div>
	
		<!-- //main content -->
	</div>
</template>

<script>
	module.exports = {
		data(){
			return{
				title: '会员级别',
				
				token: '',
				outlet_id: 0,
				outlet_name:'',
				user_name: '',
				
				setting: {},
				outlet:{},//当前店，此页特殊，用于显示级别归属店铺
				
				customergroup: {},
				
				//限额设置
				paylimit:{
					"max_pay":0,
					"hours":[
						{
							"pay":0,
							"times":0
						},
						{
							"pay":0,
							"times":0
						},
						{
							"pay":0,
							"times":0
						},
						{
							"pay":0,
							"times":0
						}
					],
					"day":{
						"pay":0,
						"times":0
					},
					"month":{
						"pay":0,
						"times":0
					}
				},
				
				disabled:  false,
				
				outletlist: [],
				
				businesshourssetting: [],
			}
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得店铺信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			console.log(lsLoginData.logined_outlet_name)
			// self.outlet_id = lsLoginData.logined_outlet_id;
			console.info('--- this.$store.state ->',this.$store.state)
			self.outlet_id = this.$store.state.current_outlet_id || lsLoginData.logined_outlet_id;
			self.outlet_name = lsLoginData.logined_outlet_name;
			self.user_name = lsLoginData.user_account;
			
			self.setting = JSON.parse(localStorage.getItem('SETTING'));
			
			self.outletlist = JSON.parse(localStorage.getItem('OUTLETLIST'));
			console.log('--- outlet list ->', self.outletlist)
			
			//指定店铺 >> 当前级别
			// let selectedOutletId = getQueryString("outlet_id");
			console.log('--- this.$route>',this.$route)
			let selectedOutletId = this.$route.params.outlet_id;
			if(selectedOutletId){
				self.customergroup = this.$route.params;
				
				self.outlet_id = selectedOutletId;
				for(let otl of self.outletlist){
					if(selectedOutletId == otl.id){
						self.outlet = otl;
					}
				}
			}
			else{
				this.$router.go(-1)  // 返回上级操作
			}
			
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.initData();
			
			// $('.scope-outlet-id').select2();
		},
		methods: {
			//未实现 未使用
			initData(){
			 	let self = this;
				
				if(self.customergroup.pay_limit && self.customergroup.pay_limit.indexOf('{')!=-1){
					self.paylimit = JSON.parse(self.customergroup.pay_limit);
				}
				console.log(self.customergroup.pay_limit,self.paylimit);
				
			 	// return;
				
			 	//调用接口
			 	self.axios.get(self.GLOBAL.baseURI + 'getOutletSetting', {
			 		params: {
			 			token: self.token,
			 			outlet_id: self.outlet_id,
			 			setting_key: 'BUSINESS_HOURS_SETTING',
			 		}
			 	})
			 	.then(function(response) {
			 		console.log(response);
			 		return response.data;
			 	})
			 	.catch(function(error) {
			 		console.log(error);
			 	})
			 	.then(function(data) {
			 		// always executed
			 		console.log(data.data);
			 		if (data.code == 200) { 
						if(data.data.setting!=''){
							self.businesshourssetting = JSON.parse(data.data.setting);//用作显示 
						}
						else{
							self.businesshourssetting = [];
						}
			 		}
			 		else if (data.code == 101) { 
			 			//登录超时
			 			location.href = "index.html";
			 		}
			 		else{
			 			alert(data.message)
			 		}
			 	});
			 	//END
			 	
			 },
			
			
			// 设置消费额度
			save(){
				let self = this;
				console.log(self.customergroup.pay_limit,self.paylimit);
				// return;
				
				console.log('--- businesshourssetting ->', self.businesshourssetting, self.paylimit.hours.length)
				
				self.disabled = true;
				
				//加入时段时间 >> 用时段设置
				// let hours = self.paylimit.hours;
				// for(let i=0; i<self.paylimit.hours.length; i++){
				// 	if(i+1 > self.businesshourssetting.length) continue;
					
				// 	self.paylimit.hours[i].start_date = self.businesshourssetting[i].start_date;
				// 	self.paylimit.hours[i].end_date = self.businesshourssetting[i].end_date;
				// }
				
				self.customergroup.pay_limit = JSON.stringify(self.paylimit);
				
				// 组装数据
				let postData = {
					token: self.token,
					outlet_id: self.outlet_id,
					group_id: self.customergroup.id,
					pay_limit: JSON.stringify(self.paylimit),
				}
				
				self.axios.post(self.GLOBAL.baseURI + 'updateCustomerGroup', postData
				)
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						// for(let i=0; i<self.customergrouplist.length; i++){
						// 	if(self.selectedIndex == i){
						// 		self.customergrouplist.splice(i,1);
						// 	}
						// }
						
						// self.customergrouplist.splice(self.selectedIndex,1,self.customergroup);
						
						alert('设置成功')
						
						// $('#sModal').modal('hide');
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						alert(data.message)
					}
				});
			},
			
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;
			
				self.initData();
			},
			
			back(){
				this.$router.go(-1)  // 返回上级操作
			},
		},
	}
	// jQuery(function(){
	// 	// $('.scope-outlet-id').select2();
	// });
</script>

<style scoped>
	/* .bootstrap-tagsinput{
		width: 100% !important;
	} */
</style>
